import { fetchContactMessengerChatApi } from 'api/chat/fetchContactMessengerChatApi'
import { fetchMessengerChatApi } from 'api/chat/fetchMessengerChatApi'
import { fetchMessengerChatWithSupportApi } from 'api/messengerSupport/fetchMessengerChatWithSupportApi'

export const fetchMessengerSwitchApi = (
  profileId: number,
  limit: number,
  offset: number,
  userHasRestrictions: boolean
) => {
  if (userHasRestrictions) {
    return fetchMessengerChatWithSupportApi(limit, offset)
  }

  if (Number(profileId) !== 0) {
    return fetchMessengerChatApi(profileId, limit, offset)
  }

  throw new Error('Messenger fetch API method is not found')
}
