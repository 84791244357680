import cookie from 'js-cookie'

import { fetchApi5 } from 'api/index'

export const deleteContactListApi = (contactSelectedIds: number[]) => {
  const s_post = cookie.get('s_post')

  return fetchApi5('/contacts', {
    method: 'DELETE',
    body: JSON.stringify({
      contactIds: contactSelectedIds,
      s_post,
    }),
  })
}
