import { AsyncThunkAction } from 'actions/actions.types'
import { clearIgnoredContactListAction } from 'actions/contactList/clearIgnoredContactListAction'
import { convertArrayStringToNumber } from 'actions/contactList/function/convertArrayStringToNumber'
import { goBackAction } from 'actions/route/routeAction'
import { fetchNoticeShortCutAction } from 'actions/uninotice/noticeShortCutAction'
import {
  markReadContactListApi,
  markUnreadContactListApi,
  moveFromIgnoreContactListApi,
} from 'api/contactList/additionalContactListApi'
import { deleteContactListApi } from 'api/contactList/deleteContactListApi'
import { redirectChatPath } from 'components/paths'
import { NoticeShortCutList } from 'components/uninotice/uninotice.types'
import { isShowLeftLayout } from 'functions/isShowLeftLayout'
import { replace } from 'functions/router'
import {
  ContactItem,
  ContactListType,
} from 'reducers/contactList/contactList.types'

export const MARK_READ_CONTACT_LIST = 'MARK_READ_CONTACT_LIST' as const

export const markReadContactListAction = (
  contactListSelected: ContactItem[]
) => {
  const contactsIds = contactListSelected.reduce<number[]>(
    (acc, item) => [...acc, item.contactId],
    []
  )

  return {
    type: MARK_READ_CONTACT_LIST,
    promise: () => markReadContactListApi(contactsIds),
    contactsIds,
  }
}

export const MARK_UNREAD_CONTACT_LIST = 'MARK_UNREAD_CONTACT_LIST' as const

export const markUnreadContactListAction = (
  contactListSelected: ContactItem[]
) => {
  const contactsIds = contactListSelected.reduce<number[]>(
    (acc, item) => [...acc, item.contactId],
    []
  )

  return {
    type: MARK_UNREAD_CONTACT_LIST,
    promise: () => markUnreadContactListApi(contactsIds),
    contactsIds,
  }
}

export const MOVE_FROM_IGNORE_CONTACT_LIST_ASYNC = 'MOVE_FROM_IGNORE_CONTACT_LIST_ASYNC' as const

export const moveFromIgnoreContactListAsyncAction = (
  users: ContactItem[],
  folderId: number,
  locale: string
): AsyncThunkAction => async (dispatch) => {
  const contactsIds = users.reduce<number[]>(
    (acc, item) => [...acc, item.contactId],
    []
  )

  const userIds = users.reduce<number[]>(
    (acc, item) => [...acc, item.anketa?.id!],
    []
  )

  await dispatch(
    moveFromIgnoreContactListAsyncPlainAction(
      contactsIds,
      userIds,
      folderId,
      locale
    )
  )

  return dispatch(
    fetchShortcutNoticeAction(
      userIds,
      NoticeShortCutList.moveCommonManyContact,
      NoticeShortCutList.moveCommonOneContact
    )
  )
}

export const moveFromIgnoreContactListAsyncPlainAction = (
  contactsIds: number[],
  userIds: number[],
  folderId: number,
  locale: string
) => ({
  type: MOVE_FROM_IGNORE_CONTACT_LIST_ASYNC,
  promise: () => moveFromIgnoreContactListApi(contactsIds, folderId, locale),
  userIds,
})

export const MOVE_TO_IGNORE_CONTACT_LIST = 'MOVE_TO_IGNORE_CONTACT_LIST' as const

export const moveToIgnoreContactListAction = (userId: number) => ({
  type: MOVE_TO_IGNORE_CONTACT_LIST,
  userId,
})

export const moveToIgnoreCombineAction = (userId: number): AsyncThunkAction => (
  dispatch,
  getState
) => {
  dispatch(moveToIgnoreContactListAction(userId))
  dispatch(clearIgnoredContactListAction())
}

export const FILTER_CONTACT_LIST = 'FILTER_CONTACT_LIST' as const

export const filterContactListAction = (
  listType: ContactListType,
  userId: number
) => ({
  type: FILTER_CONTACT_LIST,
  listType,
  userId,
})

export const fetchShortcutNoticeAction = (
  userIds: number[],
  noticeManyId: NoticeShortCutList,
  noticeOneId: NoticeShortCutList
): AsyncThunkAction => (dispatch) => {
  if (userIds.length > 1) {
    return dispatch(
      fetchNoticeShortCutAction(noticeManyId, {
        count: userIds.length,
      })
    )
  }

  if (userIds.length === 1) {
    if (userIds[0] !== 0) {
      return dispatch(
        fetchNoticeShortCutAction(noticeOneId, {
          anketa_id: userIds[0],
        })
      )
    } else {
      console.error(`profileId ${userIds[0]} is not exist`)
    }
  }
}

export const deleteContactPrepareAction = (
  listType: ContactListType,
  userIds: string[]
): AsyncThunkAction => (dispatch, getState) => {
  const { contactList } = getState()

  const _profileIds = convertArrayStringToNumber(userIds)

  const contactRemoveIds = contactList[listType]
    .filter((item) => item && _profileIds.includes(item.anketa.id))
    .reduce<number[]>((acc, item) => [...acc, item.contactId], [])

  return dispatch(
    deleteContactListWithNoticeAction(contactRemoveIds, _profileIds, listType)
  )
}

export const deleteContactListWithNoticeAction = (
  contactRemoveIds: number[],
  userIds: number[],
  listType: ContactListType
): AsyncThunkAction => async (dispatch) => {
  await dispatch(deleteContactListAction(contactRemoveIds, userIds, listType))
  dispatch(
    fetchShortcutNoticeAction(
      userIds,
      NoticeShortCutList.deleteManyContact,
      NoticeShortCutList.deleteOneContact
    )
  )
}

export const DELETE_CONTACT_LIST = 'DELETE_CONTACT_LIST' as const

export const deleteContactListAction = (
  contactSelectIds: number[],
  userIds: number[],
  listType: ContactListType
) => ({
  type: DELETE_CONTACT_LIST,
  promise: () => deleteContactListApi(contactSelectIds),
  listType,
  userIds,
})

export const combineIgnoreProfileChatAction = (
  userId: number
): AsyncThunkAction => (dispatch) => {
  dispatch(moveToIgnoreCombineAction(userId))

  if (!isShowLeftLayout()) {
    return dispatch(goBackAction())
  }

  return dispatch(replace(redirectChatPath))
}

export const REMOVE_LAST_MESSAGE_FROM_CONTACT = 'REMOVE_LAST_MESSAGE_FROM_CONTACT' as const

export const removeLastMessageFromContactAction = (userId: number) => ({
  type: REMOVE_LAST_MESSAGE_FROM_CONTACT,
  userId,
})

export type AdditionalContactListActionTypes =
  | ReturnType<typeof markReadContactListAction>
  | ReturnType<typeof markUnreadContactListAction>
  | ReturnType<typeof moveFromIgnoreContactListAsyncPlainAction>
  | ReturnType<typeof moveToIgnoreContactListAction>
  | ReturnType<typeof filterContactListAction>
  | ReturnType<typeof deleteContactListAction>
  | ReturnType<typeof removeLastMessageFromContactAction>
