import cookie from 'js-cookie'

import { fetchApi5, fetchApi } from 'api/index'
import { markReadMessageSupportChatApi } from 'api/messengerSupport/markReadMessageSupportChatApi'
import { messengerSupportAttachPhotosApi } from 'api/messengerSupport/messengerSupportAttachPhotosApi'
import { sendMessageToSupportApi } from 'api/messengerSupport/sendMessageToSupportApi'

import { editMessageApi } from './editMessageApi'

export const sendStickerApi = (profileId: number, stickerId: number) => {
  const s_post = cookie.get('s_post')

  return fetchApi5(`/users/${profileId}/sticker`, {
    method: 'POST',
    body: JSON.stringify({
      anketaId: profileId,
      stickerId,
      s_post,
    }),
  })
}

export const sendMessageApi = (
  profileId: number,
  message: string,
  repliedMessageId?: number | null
) => {
  const s_post = cookie.get('s_post')

  const body: {
    anketaId: number
    message: string
    s_post?: string
    repliedMessageId?: number
  } = {
    anketaId: profileId,
    message,
    s_post,
  }

  if (repliedMessageId) {
    body.repliedMessageId = repliedMessageId
  }

  return fetchApi5(`/users/${profileId}/post`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export const sendMessageSwitchApi = (
  profileId: number,
  message: string,
  userHasRestrictions: boolean,
  replyMessageId?: number | null,
  editMessageId?: number | null
) => {
  if (userHasRestrictions) {
    return sendMessageToSupportApi(message)
  }

  if (editMessageId) {
    return editMessageApi(profileId, editMessageId, message)
  }

  return sendMessageApi(profileId, message, replyMessageId)
}

export const sendPhotosSwitchApi = (
  profileId: number,
  albumId: number,
  photosIds: number[],
  userHasRestrictions: boolean
) => {
  if (userHasRestrictions) {
    return messengerSupportAttachPhotosApi(photosIds)
  }

  const s_post = cookie.get('s_post')

  return fetchApi5(`/users/${profileId}/photoMessage`, {
    method: 'POST',
    body: JSON.stringify({
      anketaId: Number(profileId),
      albumId: Number(albumId),
      photos: photosIds,
      s_post,
    }),
  })
}

export const sendTypingApi = (recipientId: number) =>
  fetchApi('/messenger/notifyOnMessageTyping', {
    method: 'POST',
    body: JSON.stringify({
      recipientId,
    }),
  })

export const sendDeclineApi = (profileId: number) =>
  fetchApi(`/incognito/${profileId}/request`, {
    method: 'DELETE',
  })

export const supportOpenTicketApi = () =>
  fetchApi('/support/openTicket', {
    method: 'POST',
  })

export const supportRateTicketApi = (value: number) =>
  fetchApi('/support/rateCurrentTicket', {
    method: 'POST',
    body: JSON.stringify({
      rating: value,
    }),
  })

export const deleteChatApi = (contactId: number) =>
  fetchApi(`/messenger/contacts/${contactId}/messages`, {
    method: 'DELETE',
  })

export const markReadMessageCurrentChatSwitchApi = (
  contactId: number,
  userHasRestrictions: boolean
) => {
  return userHasRestrictions
    ? // Забаненный юзер или юзер с заблоченным трекером может общаться только с поддержкой
      markReadMessageSupportChatApi()
    : markReadMessageCurrentChatApi(contactId)
}

export const markReadMessageCurrentChatApi = (contactId: number) =>
  fetchApi(`/messenger/contact/${contactId}/read`, {
    method: 'POST',
  })
