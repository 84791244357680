import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { pushModalAction } from 'actions/route/routeAction'
import { addUniNoticeToCollectionAction } from 'actions/uninotice/uniNoticeAction'
import { metaphorSlideStopChat } from 'common-constants/metaphor'
import { chatPath, contactPath } from 'components/paths'
import {
  giftPresentPath,
  stopChatTextPathParameterMerge,
  storeFrontGiftToUserPath,
  storeFrontVipPath,
} from 'components/storefront/StoreFront.paths'
import { noticePath } from 'components/uninotice/UniNotice.paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push, replace } from 'functions/router'

import { ChatEventReasons, StopChatDecision } from './constants'
import { findStopChatResolution } from './functions/findStopChatResolution'

/**
 * @param eventReason
 * @param matchUrl
 * @returns
 * в данном случае matchUrl только нужен для case в чате,
 * когда есть стоп чат и открыто другое модальное окно.
 * Нужно закрыть его и показать стоп чат.
 */

export const showStopChatIfNeeded = (
  eventReason: ChatEventReasons,
  matchUrl?: string
) => (dispatch: AppDispatchNext, getState: AppGetState) => {
  const {
    chatMessengerReducer: {
      chatData: { anketa, chatBlockedKey, stopChatNotice },
    },
  } = getState()

  if (!chatBlockedKey) {
    return false
  }

  const stopChatResolution = findStopChatResolution(eventReason, chatBlockedKey)

  if (!stopChatResolution) {
    return false
  }

  const showStopChatNotice = () => {
    if (stopChatNotice) {
      dispatch(
        addUniNoticeToCollectionAction(stopChatNotice, stopChatNotice.noticeId)
      )
      dispatch(pushModalAction(noticePath, stopChatNotice.noticeId))
    }
  }

  /** systemReducer.baseUrl тут использовать нельзя, т.к. он не всегда актуальный */
  const baseUrl = location.pathname

  const openStoreFrontGift = () => {
    if (anketa?.id) {
      /**
       * В случае если мы в чате
       * и есть другие модальные окна то нам нужно их закрыть и показать стоп чат.
       */
      const url = mergeAllUrls(
        storeFrontGiftToUserPath,
        String(anketa.id),
        giftPresentPath,
        stopChatTextPathParameterMerge
      )

      if (matchUrl && matchUrl !== baseUrl) {
        dispatch(replace(matchUrl))
        dispatch(push(mergeAllUrls(matchUrl, url)))
      } else {
        dispatch(push(mergeAllUrls(baseUrl, url)))
      }
    } else {
      throw new Error(
        `Missing anketaId when trying to open storefront gift. anketaId: "${anketa?.id}"`
      )
    }
  }

  const openStoreFrontVip = () => {
    if (anketa?.id) {
      /**
       * Для некоторый причин стопчата, витрина покупки вип может открываться в чате.
       * В таком случае нужно делать replace, иначе модал закрыть не получится и мы попадем в петлю роутера.
       */
      const chatFullPath = mergeAllUrls(chatPath, anketa.id, contactPath)
      const method = baseUrl === chatFullPath ? replace : push

      dispatch(
        method(
          mergeAllUrls(
            baseUrl,
            storeFrontVipPath,
            metaphorSlideStopChat,
            'false',
            stopChatTextPathParameterMerge
          )
        )
      )
    } else {
      throw new Error(
        `Missing anketaId when trying to open storefront vip. anketaId: "${anketa?.id}"`
      )
    }
  }

  switch (stopChatResolution.decision) {
    case StopChatDecision.ShowStopChatNotice:
      showStopChatNotice()
      break
    case StopChatDecision.OpenStoreFrontGift:
      openStoreFrontGift()
      break
    case StopChatDecision.OpenStoreFrontVip:
      openStoreFrontVip()
      break
  }

  return true
}
