import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import {
  forceReplaceMessagesAction,
  markAllReadAction,
  toggleDeferredMarkAllReaded,
} from 'actions/chatMessengerAction'
import { tryMergeChunk } from 'components/page/Chat/Messenger/Messenger.functions'
import { ChatMessage } from 'reducers/messenger/ChatMessengerState'

import { fetchMessengerChatAction } from './fetchMessengerChatAction'

/**
 * Используется для обновления информации о чате (авто удаления чата) в том числе.
 * @param profileId
 */

export const loadMoreNewMessagesAction = (profileId: number) => async (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const currentMessages = getState().chatMessengerReducer.messages

  if (!profileId) {
    console.error(
      'Load more messages profile not found',
      profileId,
      new Error()
    )
    return
  }

  let chunkLength = 0
  let merged: ChatMessage[] | null = null

  while (merged === null) {
    chunkLength += 10

    const { result } = ((await dispatch(
      fetchMessengerChatAction({
        profileId,
        limit: chunkLength,
        loadMoreNew: true,
      })
    )) as unknown) as { result: { messages: ChatMessage[] } }

    if (chunkLength === 110) {
      console.error('too many merges')
      // break
    }

    merged = tryMergeChunk(currentMessages, result.messages)
  }

  dispatch(forceReplaceMessagesAction(merged))

  const hasDeferred = getState().chatMessengerReducer.deferredMarkAllReaded
  if (hasDeferred) {
    dispatch(markAllReadAction())
    dispatch(toggleDeferredMarkAllReaded(false))
  }
}
